<template>
  <div class="page" style="text-align: left; padding: 45px">
    <div class="row">
      <div style="width: 99%">
        <div class="header" id="header" style="width: 100%; height: 100px">
          <div style="width: 11%; float: left">
            <img
              src="../../assets/print_logo.jpg"
              style="width: 100px; height: 100px"
            />
          </div>
          <div style="width: 89%; float: right">
            <p
              style="
                font-size: 28px;
                font-family: Times New Roman;
                text-align: center;
                vertical-align: text-top;
              "
            >
              <b v-if="printModel.form.BaseInfoEditPrintTitleCheckbox == false"
                >Draco Natural Products, Inc.</b
              >
              <b v-if="printModel.form.BaseInfoEditPrintTitleCheckbox">{{
                printModel.form.BaseInfoEditPrintTitle
              }}</b>
            </p>
            <p
              style="
                font-size: 28px;
                font-family: Times New Roman;
                text-align: center;
                vertical-align: text-top;
              "
            >
              <b><u>分析检测报告</u></b>
            </p>
          </div>
        </div>
        <div style="clear: both"></div>
        <!-- <table
          style="width: 100%; text-align: center; font-size: 12px"
          border="1px solid gray"
          cellspacing="0"
          class="tab"
        >
          <tr>
            <td>
              <b>Base Info</b>
            </td>
          </tr>
        </table> -->
        <!-- Product info -->
        <div>
          <div style="width: 100%">
            <table style="width: 100%">
              <tbody>
              
              <tr v-show="printModel.form.productType">
                <td style="width: 25%"><b>产品类别</b></td>
                <td>
                  {{ printModel.form.productType }}
                </td>
              </tr>
              <tr v-show="printModel.form.BaseInfoProductName">
                <td style="width: 25%"><b>产品名称</b></td>
                <td style="width: 75%" colspan="3">
                  {{ printModel.form.BaseInfoProductName }}
                </td>
              </tr>
              <tr v-show="printModel.form.versionNumber">
                <td style="width: 25%"><b>版本号</b></td>
                <td style="width: 75%" colspan="3">
                  {{ printModel.form.versionNumber }}
                </td>
              </tr>
              <tr v-show="printModel.form.BaseInfoLot">
                <td style="width: 25%"><b>批号</b></td>
                <td style="width: 75%" colspan="3">
                  {{ printModel.form.BaseInfoLot }}
                </td>
              </tr>
              <tr>
                <td v-show="printModel.form.BaseInfoItemCode" style="width: 25%"><b>产品编号</b></td>
                <td v-show="printModel.form.BaseInfoItemCode">
                  {{ printModel.form.BaseInfoItemCode }}
                </td>
                <td v-show="printModel.form.BaseInfoCountryofOrigin" style="width: 25%"><b>原产地</b></td>
                <td v-show="printModel.form.BaseInfoCountryofOrigin">
                  {{ printModel.form.BaseInfoCountryofOrigin }}
                </td>
              </tr>

              <tr>
                <td v-show="printModel.form.BaseInfoManufacturingDate" style="width: 25%">
                  <b>生产日期</b>
                </td>
                <td v-show="printModel.form.BaseInfoManufacturingDate" style="width: 25%">
                  {{ formatTime(printModel.form.BaseInfoManufacturingDate,"yyyy-MM-dd") }}
                </td>
                <td v-show="printModel.form.BaseInfoExpirationDate" style="width: 25%">
                  <b>有效期</b>
                </td>
                <td v-show="printModel.form.BaseInfoExpirationDate" style="width: 25%">
                      {{ formatTime(printModel.form.BaseInfoExpirationDate,"yyyy-MM-dd") }}
                </td>
              </tr>

              <!-- <tr v-show="printModel.form.BaseInfoCustomRowKey1">
                <td style="width: 25%"><b>{{ printModel.form.BaseInfoCustomRowKey1 }}</b></td>
                <td colspan="3">
                  {{
                    printModel.form.BaseInfoCustomRowValue1
                  }}
                </td>
              </tr>
              <tr v-show="printModel.form.BaseInfoCustomRowKey2">
                <td style="width: 25%"><b>{{ printModel.form.BaseInfoCustomRowKey2 }}</b></td>
                <td colspan="3">
                  {{
                    printModel.form.BaseInfoCustomRowValue2
                  }}
                </td>
              </tr> -->
              </tbody>
              <!-- <tbody
                v-for="(item, baseIndex) in printModel.baseCustomFieldAttr"
                :key="baseIndex+88"
              >
                <tr v-show="item.customKey">
                  <td style="width: 30%">
                    <b>{{ item.customKey }}</b>
                  </td>
                  <td colspan="3">
                    <span t-field="o.apperance">{{ item.customValue }}</span>
                  </td>
                </tr>
              </tbody> -->
            </table>
            <table style="width: 99%" v-show="printModel.baseInfoCustomFieldList && printModel.baseInfoCustomFieldList.length>0">
              <tr v-for="(baseinfoCustom,baseinfoIndex) in printModel.baseInfoCustomFieldList" :key="baseinfoIndex">
                <td style="width: 25%"><b>{{baseinfoCustom.baseInfoInput1}}</b></td>
                <td style="width: 75%">
                  {{ baseinfoCustom.baseInfoInput2 }}
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div style="clear: both"></div>
        <div style="height: 20px"></div>
        <div style="font-size: 18px">
          <b><u>标准规格</u></b>
        </div>

        <!-- 遍历table -->
        <template v-if="isSingle().count > 1">
          <table
            style="width: 98%;border-collapse: collapse;"
            cellspacing="0"
            class="tab"
          >
            <thead>
              <tr style="text-align: left;border-bottom:2px solid gray">
                <th v-show="printModel.commonNameArr.length > 0">通用名/其他名称</th>
                <th v-show="printModel.inclNameArr.length > 0">INCI名称</th>
                <th v-show="printModel.botanicalNameArr.length > 0">植物学名</th>
                <th v-show="printModel.partUsedArr.length > 0">使用部位</th>
              </tr>
            </thead>
            <tbody>
              <tr :key="i" v-for="(tag, i) in maxArr">
                <td v-show="printModel.commonNameArr.length > 0">
                  <div v-show="i <= printModel.commonNameArr.length">
                    {{ printModel.commonNameArr[i] }}
                  </div>
                </td>
                <td v-show="printModel.inclNameArr.length > 0">
                  <div v-show="i <= printModel.inclNameArr.length">
                    {{ printModel.inclNameArr[i] }}
                  </div>
                </td>
                <td v-show="printModel.botanicalNameArr.length > 0">
                  <div v-show="i <= printModel.botanicalNameArr.length">
                    {{ printModel.botanicalNameArr[i] }}
                  </div>
                </td>
                <td v-show="printModel.partUsedArr.length > 0">
                  <div v-show="i <= printModel.partUsedArr.length">
                    {{ printModel.partUsedArr[i] }}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </template>
        <div style="width: 100%" v-else-if="isSingle().count == 1">
          <table style="width: 100%" cellspacing="0" class="tab">
            <tr>
              <td style="width: 25%">
                <b>{{ isSingle().name }}</b>
              </td>
              <td style="width: 75%">
                <span v-for="(item, val, index) in isSingle().arr" :key="index"
                  >{{ item
                  }}<span v-if="val + 1 < isSingle().arr.length">, </span></span
                >
              </td>
            </tr>
          </table>
        </div>
        
        <template v-if="isShowTable">
          <table
            style="width: 98%;margin-top:10px;"
            cellspacing="0"
            class="tab"
          >
            <tbody>
              <tr>
                <td v-show="printModel.form.tableInput1" style="width:20%;">{{ printModel.form.tableInput1 }}</td>
                <td v-show="printModel.form.tableInput2" style="width:20%;">{{ printModel.form.tableInput2 }}</td>
                <td v-show="printModel.form.tableInput3" style="width:20%;">{{ printModel.form.tableInput3 }}</td>
                <td v-show="printModel.form.tableInput4" style="width:20%;">{{ printModel.form.tableInput4 }}</td>
                <td v-show="printModel.form.tableInput5" style="width:20%;">{{ printModel.form.tableInput5 }}</td>
              </tr>
              <tr v-for="(item, tableInputIndex) in printModel.tableInputCustomFieldAttr" :key="tableInputIndex + 1">
                <td v-show="item.tableInput1">{{ item.tableInput1 }}</td>
                <td v-show="item.tableInput2">{{ item.tableInput2 }}</td>
                <td v-show="item.tableInput3">{{ item.tableInput3 }}</td>
                <td v-show="item.tableInput4">{{ item.tableInput4 }}</td>
                <td v-show="item.tableInput5">{{ item.tableInput5 }}</td>
              </tr>
            </tbody>
          </table>
        </template>
        <!-- Product info -->
        <div>
          <div style="width: 100%">
            <table style="width: 100%">
              <tbody>
                <tr v-show="printModel.form.GeneralSpecAppearance">
                  <td style="width: 25%"><b>外观</b></td>
                  <td>
                    {{ printModel.form.GeneralSpecAppearance }}
                  </td>
                </tr>
              <tr v-show="printModel.form.otherIngredients">
                <td style="width: 25%"><b>其他成分</b></td>
                <td>
                  {{ printModel.form.otherIngredients }}
                </td>
              </tr>
                <tr v-show="printModel.form.GeneralSpecSolubility">
                  <td style="width: 25%"><b>溶解度</b></td>
                  <td>
                    {{ printModel.form.GeneralSpecSolubility }}
                  </td>
                </tr>
                <tr
                  v-show="
                    printModel.form.GeneralSpecImportantConstituentsInPlant
                  "
                >
                  <td style="width: 25%">
                    <b>植物主要成分</b>
                  </td>
                  <td>
                    {{
                      printModel.form.GeneralSpecImportantConstituentsInPlant
                    }}
                  </td>
                </tr>
                <tr v-show="printModel.form.GeneralSpecHomogeneity">
                  <td style="width: 25%"><b>均匀度</b></td>
                  <td>
                    {{ printModel.form.GeneralSpecHomogeneity }}
                  </td>
                </tr>
                <tr v-show="printModel.form.GeneralSpecExtractionProcess">
                  <td style="width: 25%"><b>提取过程</b></td>
                  <td>
                    {{ printModel.form.GeneralSpecExtractionProcess }}
                  </td>
                </tr>
                <tr v-show="printModel.form.GeneralSpecSolventForExtraction">
                  <td style="width: 25%"><b>提取溶剂</b></td>
                  <td>
                    {{ printModel.form.GeneralSpecSolventForExtraction }}
                  </td>
                </tr>
                <tr v-show="printModel.form.GeneralSpecPesticides">
                  <td style="width: 25%"><b>农药残留</b></td>
                  <td>
                    {{ printModel.form.GeneralSpecPesticides }}
                  </td>
                </tr>
                <tr v-show="printModel.form.GeneralSpecCertification">
                  <td style="width: 25%"><b>证书</b></td>
                  <td>
                    {{ printModel.form.GeneralSpecCertification }}
                  </td>
                </tr>

                <tr v-show="printModel.form.GeneralSpecCustomRowKey1">
                  <td style="width: 25%">
                    <b>{{ printModel.form.GeneralSpecCustomRowKey1 }}</b>
                  </td>
                  <td style="width: 75%">
                    {{ printModel.form.GeneralSpecCustomRowValue1 }}
                  </td>
                </tr>
                <tr v-show="printModel.form.GeneralSpecCustomRowKey2">
                  <td style="width: 25%">
                    <b>{{ printModel.form.GeneralSpecCustomRowKey2 }}</b>
                  </td>
                  <td style="width: 75%">
                    {{ printModel.form.GeneralSpecCustomRowValue2 }}
                  </td>
                </tr>
              </tbody>
              <tbody
                v-for="(item, genIndex) in printModel.genSpecCustomFieldAttr"
                :key="genIndex"
              >
                <tr v-show="item.customKey">
                  <td style="width: 30%">
                    <b>{{ item.customKey }}</b>
                  </td>
                  <td style="width: 70%">
                    <span t-field="o.apperance">{{ item.customValue }}</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div style="height: 20px"></div>
        <div style="font-size: 18px">
          <b><u>分析</u></b>
        </div>
        <div style="height: 10px"></div>
        <div>
          <div style="width: 100%">
            <table style="width: 98%" cellspacing="0" class="tab">
              <tbody>
                <tr>
                  <td style="width: 25%">
                    <b>检测项目</b>
                  </td>
                  <td style="width: 25%">
                    <b>标准</b>
                  </td>
                  <td style="width: 25%">
                    <b>结果</b>
                  </td>
                  <td style="width: 25%">
                    <b>测试方法</b>
                  </td>
                </tr>

                <tr
                  v-show="
                    printModel.form.SpecificationMoisture ||
                    printModel.form.ResultMoisture 
                  "
                >
                  <td class="FontWeight"><b>水分2</b></td>
                  <td>
                    {{ printModel.form.SpecificationMoisture }}
                  </td>
                  <td>
                    {{ printModel.form.ResultMoisture }}
                  </td>
                  <td>
                    {{ printModel.form.TestMethodMoisture }}
                  </td>
                </tr>
                <tr
                  v-show="
                    printModel.form.SpecificationSpecificGravity ||
                    printModel.form.ResultSpecificGravity 
                  "
                >
                  <td class="FontWeight"><b>比重</b></td>
                  <td>
                    {{ printModel.form.SpecificationSpecificGravity }}
                  </td>
                  <td>
                    {{ printModel.form.ResultSpecificGravity }}
                  </td>
                  <td>
                    {{ printModel.form.TestMethodSpecificGravity }}
                  </td>
                </tr>
                <tr
                  v-show="
                    printModel.form.SpecificationRefractiveIndex ||
                    printModel.form.ResultRefractiveIndex 
                  "
                >
                  <td class="FontWeight"><b>折光率</b></td>
                  <td>
                    {{ printModel.form.SpecificationRefractiveIndex }}
                  </td>
                  <td>
                    {{ printModel.form.ResultRefractiveIndex }}
                  </td>
                  <td>
                    {{ printModel.form.TestMethodRefractiveIndex }}
                  </td>
                </tr>

                <tr
                  v-show="
                    printModel.form.SpecificationOpticalRotation ||
                    printModel.form.ResultOpticalRotation 
                  "
                >
                  <td class="FontWeight"><b>旋光度</b></td>
                  <td>
                    {{ printModel.form.SpecificationOpticalRotation }}
                  </td>
                  <td>
                    {{ printModel.form.ResultOpticalRotation }}
                  </td>
                  <td>
                    {{ printModel.form.TestMethodOpticalRotation }}
                  </td>
                </tr>
                <tr
                  v-show="
                    printModel.form.SpecificationTotalHeavyMetals ||
                    printModel.form.ResultTotalHeavyMetals
                  "
                >
                  <td class="FontWeight"><b>总重金属</b></td>
                  <td>
                    &lt;{{ printModel.form.SpecificationTotalHeavyMetals }}ppm
                  </td>
                  <td>
                    {{ printModel.form.ResultTotalHeavyMetals }} {{checkNumber(printModel.form.ResultTotalHeavyMetals)?'ppm':''}}
                  </td>
                  <td>
                    {{ printModel.form.TestMethodTotalHeavyMetals }}
                  </td>
                </tr>
                <tr
                  v-show="
                    printModel.form.SpecificationArsenicAS ||
                    printModel.form.ResultArsenicAS
                  "
                >
                  <td class="FontWeight"><b>砷</b></td>
                  <td>{{ printModel.form.SpecificationArsenicAS }}ppm</td>
                  <td>
                    {{ printModel.form.ResultArsenicAS }}
                  </td>
                  <td>
                    {{ printModel.form.TestMethodArsenicAS }}
                  </td>
                </tr>
                <tr
                  v-show="
                    printModel.form.SpecificationCadmiumCd ||
                    printModel.form.ResultCadmiumCd
                  "
                >
                  <td class="FontWeight"><b>镉</b></td>
                  <td>{{ printModel.form.SpecificationCadmiumCd }}ppm</td>
                  <td>
                    {{ printModel.form.ResultCadmiumCd }}
                  </td>
                  <td>
                    {{ printModel.form.TestMethodCadmiumCd }}
                  </td>
                </tr>
                <tr
                  v-show="
                    printModel.form.SpecificationLeadPd ||
                    printModel.form.ResultLeadPd
                  "
                >
                  <td class="FontWeight"><b>铅</b></td>
                  <td>{{ printModel.form.SpecificationLeadPd }}ppm</td>
                  <td>
                    {{ printModel.form.ResultLeadPd }}
                  </td>
                  <td>
                    {{ printModel.form.TestMethodLeadPd }}
                  </td>
                </tr>

                <tr
                  v-show="
                    printModel.form.SpecificationMercuryHg ||
                    printModel.form.ResultMercuryHg
                  "
                >
                  <td class="FontWeight"><b>汞</b></td>
                  <td>{{ printModel.form.SpecificationMercuryHg }}ppm</td>
                  <td>
                    {{ printModel.form.ResultMercuryHg }}
                  </td>
                  <td>
                    {{ printModel.form.TestMethodMercuryHg }}
                  </td>
                </tr>
                <tr
                  v-show="
                    printModel.form.SpecificationColor ||
                    printModel.form.ResultColor
                  "
                >
                  <td class="FontWeight"><b>颜色</b></td>
                  <td>
                    {{ printModel.form.SpecificationColor }}
                  </td>
                  <td>
                    {{ printModel.form.ResultColor }}
                  </td>
                  <td>
                    {{ printModel.form.TestMethodColor }}
                  </td>
                </tr>
                <tr
                  v-show="
                    printModel.form.SpecificationOdor ||
                    printModel.form.ResultOdor
                  "
                >
                  <td class="FontWeight"><b>气味</b></td>
                  <td>
                    {{ printModel.form.SpecificationOdor }}
                  </td>
                  <td>
                    {{ printModel.form.ResultOdor }}
                  </td>
                  <td>
                    {{ printModel.form.TestMethodOdor }}
                  </td>
                </tr>
                <tr
                  v-show="
                    printModel.form.SpecificationTaste ||
                    printModel.form.ResultTaste
                  "
                >
                  <td class="FontWeight"><b>味道</b></td>
                  <td>
                    {{ printModel.form.SpecificationTaste }}
                  </td>
                  <td>
                    {{ printModel.form.ResultTaste }}
                  </td>
                  <td>
                    {{ printModel.form.TestMethodTaste }}
                  </td>
                </tr>

                <tr
                  v-show="
                    printModel.form.SpecificationCustomRowKey1 ||
                    printModel.form.ResultCustomRowKey1 ||
                    printModel.form.TestMethodCustomRowKey1
                  "
                >
                  <td style="width: 25%">
                    <b> {{ printModel.form.SpecificationCustomRowKey1 }}</b>
                  </td>
                  <td style="width: 25%">
                    {{ printModel.form.SpecificationCustomRowValue1 }}
                  </td>
                  <td style="width: 25%">
                    {{ printModel.form.ResultCustomRowValue1 }}
                  </td>
                  <td style="width: 25%">
                    {{ printModel.form.TestMethodCustomRowValue1 }}
                  </td>
                </tr>
                <tr
                  v-show="
                    printModel.form.SpecificationCustomRowKey2 ||
                    printModel.form.ResultCustomRowKey2 ||
                    printModel.form.TestMethodCustomRowKey2
                  "
                >
                  <td style="width: 25%">
                    <b>{{ printModel.form.SpecificationCustomRowKey2 }}</b>
                  </td>
                  <td style="width: 25%">
                    {{ printModel.form.SpecificationCustomRowValue2 }}
                  </td>
                  <td style="width: 25%">
                    {{ printModel.form.ResultCustomRowValue2 }}
                  </td>
                  <td style="width: 25%">
                    {{ printModel.form.TestMethodCustomRowValue2 }}
                  </td>
                </tr>
                <tr
                  v-show="
                    printModel.form.SpecificationCustomRowKey3 ||
                    printModel.form.ResultCustomRowKey3 ||
                    printModel.form.TestMethodCustomRowKey3
                  "
                >
                  <td style="width: 25%">
                    <b>{{ printModel.form.SpecificationCustomRowKey3 }}</b>
                  </td>
                  <td style="width: 25%">
                    {{ printModel.form.SpecificationCustomRowValue3 }}
                  </td>
                  <td style="width: 25%">
                    {{ printModel.form.ResultCustomRowValue3 }}
                  </td>
                  <td style="width: 25%">
                    {{ printModel.form.TestMethodCustomRowValue3 }}
                  </td>
                </tr>

                <tr
                  v-show="
                    printModel.form.SpecificationCustomRowKey4 ||
                    printModel.form.ResultCustomRowKey4 ||
                    printModel.form.TestMethodCustomRowKey4
                  "
                >
                  <td style="width: 25%">
                    <b>{{ printModel.form.SpecificationCustomRowKey4 }}</b>
                  </td>
                  <td style="width: 25%">
                    {{ printModel.form.SpecificationCustomRowValue4 }}
                  </td>
                  <td style="width: 25%">
                    {{ printModel.form.ResultCustomRowValue4 }}
                  </td>
                  <td style="width: 25%">
                    {{ printModel.form.TestMethodCustomRowValue4 }}
                  </td>
                </tr>
              </tbody>
              <tbody
                v-for="(
                  item, analysisIndex
                ) in printModel.analysisCustomFieldAttr"
                :key="analysisIndex"
              >
                <tr v-show="item.customKey">
                  <td style="width: 25%">
                    <b>{{ item.customKey }}</b>
                  </td>
                  <td style="width: 25%">
                    {{ item.customValue }}
                  </td>
                  <td style="width: 25%">
                    {{ item.customValue2 }}
                  </td>
                  <td style="width: 25%">
                    {{ item.customValue1 }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div style="height: 20px"></div>

        <div style="font-size: 18px">
          <b><u>微生物检验</u></b>
        </div>
        <div style="height: 10px"></div>
        <div>
          <div style="width: 100%">
            <table style="width: 98%" cellspacing="0" class="tab">
              <tbody>
                <tr>
                  <td style="width: 25%">
                    <b>检测项目</b>
                  </td>
                  <td style="width: 25%">
                    <b>标准</b>
                  </td>
                  <td style="width: 25%">
                    <b>结果</b>
                  </td>
                  <td style="width: 25%">
                    <b>测试方法</b>
                  </td>
                </tr>

                <tr
                  v-show="
                    printModel.form.SpecificationTotalPlateCount ||
                    printModel.form.ResultTotalPlateCount
                  "
                >
                  <td class="FontWeight"><b>细菌总数</b></td>
                  <td>
                    &lt;{{ printModel.form.SpecificationTotalPlateCount }}cfu /
                    g
                  </td>
                  <td>
                    {{ printModel.form.ResultTotalPlateCount }} {{checkNumber(printModel.form.ResultTotalPlateCount)?'cfu/g':''}}
                  </td>
                  <td>
                    {{ printModel.form.TestMethodTotalPlateCount }}
                  </td>
                </tr>

                <tr
                  v-show="
                    printModel.form.SpecificationYeastAndMold ||
                    printModel.form.ResultYeastAndMold
                  "
                >
                  <td class="FontWeight"><b>酵母和霉菌</b></td>
                  <td>
                    &lt;{{ printModel.form.SpecificationYeastAndMold }}cfu / g
                  </td>
                  <td>
                    {{ printModel.form.ResultYeastAndMold }} {{checkNumber(printModel.form.ResultYeastAndMold)?'cfu/g':''}}
                  </td>
                  <td>
                    {{ printModel.form.TestMethodYeastAndMold }}
                  </td>
                </tr>
                <tr v-show="printModel.form.SpecificationEColi">
                  <td class="FontWeight"><b>大肠杆菌</b></td>
                  <td>
                    {{ printModel.form.SpecificationEColi }}
                  </td>
                  <td>
                    {{ printModel.form.ResultEColi }}
                  </td>
                  <td>
                    {{ printModel.form.TestMethodEColi }}
                  </td>
                </tr>
                <tr v-show="printModel.form.SpecificationSalmonella">
                  <td class="FontWeight"><b>沙门氏菌</b></td>
                  <td>
                    {{ printModel.form.SpecificationSalmonella }}
                  </td>
                  <td>
                    {{ printModel.form.ResultSalmonella }}
                  </td>
                  <td>
                    {{ printModel.form.TestMethodSalmonella }}
                  </td>
                </tr>
                <tr v-show="printModel.form.SpecificationStaphAureus">
                  <td class="FontWeight"><b>金色葡萄球菌</b></td>
                  <td>
                    {{ printModel.form.SpecificationStaphAureus }}
                  </td>
                  <td>
                    {{ printModel.form.ResultStaphAureus }}
                  </td>
                  <td>
                    {{ printModel.form.TestMethodStaphAureus }}
                  </td>
                </tr>
                <tr
                  v-show="
                    printModel.form.SpecificationTotalColiforms ||
                    printModel.form.ResultTotalColiforms
                  "
                >
                  <td class="FontWeight"><b>大肠菌群</b></td>
                  <!-- <td>
                    &lt;{{ printModel.form.SpecificationTotalColiforms }}MPN/g
                  </td> -->
                  
                  <td v-if="
                  !printModel.form.SpecificationTotalColiforms || 
                  printModel.form.SpecificationTotalColiforms.toLowerCase()=='negative' || 
                  printModel.form.SpecificationTotalColiforms.toLowerCase()=='阴性'">
                    {{ printModel.form.SpecificationTotalColiforms }}
                  </td>
                  <td v-else>
                    &lt;{{ printModel.form.SpecificationTotalColiforms }} MPN/g
                  </td>
                  <td>
                    {{ printModel.form.ResultTotalColiforms }}
                  </td>
                  <td>
                    {{ printModel.form.TestMethodTotalColiforms }}
                  </td>
                </tr>

                <tr
                  v-show="
                    printModel.form.MTSpecificationCustomRowKey1 ||
                    printModel.form.MTResultCustomRowKey1 ||
                    printModel.form.MTTestMethodCustomRowKey1
                  "
                >
                  <td style="width: 25%">
                    <b>{{ printModel.form.MTSpecificationCustomRowKey1 }}</b>
                  </td>
                  <td style="width: 25%">
                    {{ printModel.form.MTSpecificationCustomRowValue1 }}
                  </td>
                  <td style="width: 25%">
                    {{ printModel.form.MTResultCustomRowValue1 }}
                  </td>
                  <td style="width: 25%">
                    {{ printModel.form.MTTestMethodCustomRowValue1 }}
                  </td>
                </tr>

                <tr
                  v-show="
                    printModel.form.MTSpecificationCustomRowKey2 ||
                    printModel.form.MTResultCustomRowKey2 ||
                    printModel.form.MTTestMethodCustomRowKey2
                  "
                >
                  <td style="width: 25%">
                    <b>{{ printModel.form.MTSpecificationCustomRowKey2 }}</b>
                  </td>
                  <td style="width: 25%">
                    {{ printModel.form.MTSpecificationCustomRowValue2 }}
                  </td>
                  <td style="width: 25%">
                    {{ printModel.form.MTResultCustomRowValue2 }}
                  </td>
                  <td style="width: 25%">
                    {{ printModel.form.MTTestMethodCustomRowValue2 }}
                  </td>
                </tr>

                <tr
                  v-show="
                    printModel.form.MTSpecificationCustomRowKey3 ||
                    printModel.form.MTResultCustomRowKey3 ||
                    printModel.form.MTTestMethodCustomRowKey3
                  "
                >
                  <td style="width: 25%">
                    <b> {{ printModel.form.MTSpecificationCustomRowKey3 }}</b>
                  </td>
                  <td style="width: 25%">
                    {{ printModel.form.MTSpecificationCustomRowValue3 }}
                  </td>
                  <td style="width: 25%">
                    {{ printModel.form.MTResultCustomRowValue3 }}
                  </td>
                  <td style="width: 25%">
                    {{ printModel.form.MTTestMethodCustomRowValue3 }}
                  </td>
                </tr>

                <tr
                  v-show="
                    printModel.form.MTSpecificationCustomRowKey4 ||
                    printModel.form.MTResultCustomRowKey4 ||
                    printModel.form.MTTestMethodCustomRowKey4
                  "
                >
                  <td style="width: 25%">
                    <b>{{ printModel.form.MTSpecificationCustomRowKey4 }}</b>
                  </td>
                  <td style="width: 25%">
                    {{ printModel.form.MTSpecificationCustomRowValue4 }}
                  </td>
                  <td style="width: 25%">
                    {{ printModel.form.MTResultCustomRowValue4 }}
                  </td>
                  <td style="width: 25%">
                    {{ printModel.form.MTTestMethodCustomRowValue4 }}
                  </td>
                </tr>
              </tbody>

              <tbody
                v-for="(item, micIndex) in printModel.micTestsCustomFieldAttr"
                :key="micIndex"
              >
                <tr v-show="item.customKey">
                  <td style="width: 25%">
                    <b>{{ item.customKey }}</b>
                  </td>
                  <td style="width: 25%">
                    {{ item.customValue }}
                  </td>
                  <td style="width: 25%">
                    {{ item.customValue2 }}
                  </td>
                  <td style="width: 25%">
                    {{ item.customValue1 }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div>
          <div style="height: 20px"></div>
          <div style="font-size: 18px">
            <b><u>注释</u></b>
          </div>

          <p style="margin: 0px; padding: 0px"></p>
          <p style="margin: 0px; padding: 0px"></p>
        </div>

        <div
          class="footer"
          id="footer"
          style="
            font-size: 11px;
            font-family: Times New Roman;
            margin-top: 10px;
          "
        >
          
            <div>
              <span
                ><i
                  >* 不同批次产品的颜色可能会因天然原材料的自然变化而产生差异。</i
                ></span
              >
            </div>
            <div style="height: 10px"></div>
          <div>
            <span
              ><i
                >* 产品含抗氧化化合物，可能会随着时间推移颜色变深但不影响使用效果。</i
              ></span
            >
          </div>
          <div style="height: 10px"></div>
            <div>
              <span
                ><i
                  >* 天然植物产品在长期静置时可能会产生一些产品本身的沉淀物，这不会影响产品的质量；如发现产品有沉淀，请在使用前适当加热并搅拌至充分混合。</i
                ></span
              >
            </div>
          <p v-for="(item, index) in printModel.newNoteArr" :key="index">
            * {{item.content}}
          </p>
        </div>
        <div style="height: 10px"></div>
        <div>
          <div style="float: left">
            质量保证员-
            <span
              style="font-size: 28px; font-family: 'Comic Sans MS', cursive"
              >{{ printModel.formData.updateName }}</span
            >
          </div>
          <div style="float: right">日期: {{ formatDate(printModel.formData.createTime) }}</div>
        </div>
          <div style="height: 20px;clear:both;"></div>
          <div style="font-size: 14px;">
            <b><u>Disclaimer: The product specifications, limits, test methods, and information contained on this document are accurate to the best of our knowledge based upon general industry information, available scientific data, and customer requests. These values are not intended to be used as specifications for a finished product and should be used only at the sole discretion of the purchaser. According to GMP regulations, the test results of the product must be independently verified by the purchasing party. The appropriate use of this product is the sole responsibility of the user or the purchasing party.</u></b>
          </div>

          <div style="height: 10px"></div>
          <div style="text-align: center">
            <b
              ><u>
                539 Parrott Street San Jose CA, 95112 Tel: 408-287-7871
                Fax:408-287-8838</u
              ></b
            >
          </div>
        <div style="height: 20px"></div>
        <div></div>
        <FooterSigner></FooterSigner>
      </div>
    </div>
  </div>
</template>
<script>
import FooterSigner from "./FooterSigner";
import util from "../../common/base";
export default {
  name: "CSFMU",
  props: ["printModel"],
  components: { FooterSigner },
  data() {
    return {
      loginName: localStorage.getItem("loginUserNameKey"),
    };
  },
  computed: {
    maxArr() {
      let tmp = [
        this.printModel.inclNameArr.length,
        this.printModel.commonNameArr.length,
        this.printModel.botanicalNameArr.length,
        this.printModel.partUsedArr.length,
      ].sort();
      return tmp[tmp.length - 1];
    },
    isShowTable(){
      if(this.printModel.form.tableInput1 || this.printModel.form.tableInput2 || this.printModel.form.tableInput3 || this.printModel.form.tableInput4 || this.printModel.form.tableInput5){
        return true
      }
      return false
    }
  },
  methods: {
    getTime() {
      return util.methods.getLocalTime(-5);
    },
    formatDate(time) {
      return util.methods.formatDate({ date: time });
    },
    formatTime(time,format){
      return util.methods.formatTime(time,format);
    },
    isSingle() {
      var count = 0;
      var name = "";
      var arr = [];
      if (this.printModel.commonNameArr.length >= 1) {
        name = "Common Name";
        count = count + 1;
        arr = this.printModel.commonNameArr;
      }
      if (this.printModel.inclNameArr.length >= 1) {
        name = "INCI name";
        count = count + 1;
        arr = this.printModel.inclNameArr;
      }
      if (this.printModel.botanicalNameArr.length >= 1) {
        name = "Botanical Name";
        count = count + 1;
        arr = this.printModel.botanicalNameArr;
      }
      if (this.printModel.partUsedArr.length >= 1) {
        name = "Part Used";
        count = count + 1;
        arr = this.printModel.partUsedArr;
      }
      let res = { count, name, arr };
      return res;
    },
    checkNumber(number){
      let firstStr=(number + "").substring(0,1)
      if(firstStr=='<' || firstStr=='≤'){
        number = number.substring(1)
      }
      var numReg = /^[0-9]+([.]{1}[0-9]+){0,1}$/
      var numRe = new RegExp(numReg)
      if (!numRe.test(number)) {
        return false
      }
      return true
    }
  },
  created() {
    let userInfo = JSON.parse(localStorage.getItem("specUserInfo"));
    if (userInfo) {
      localStorage.setItem("loginUserNameKey", userInfo.username);
    }
  },
};
</script>

<style scoped>
b {
  font-size: 14px;
}
.tab {
  margin-top: 5px;
}
table,
p {
  margin: 0px;
  padding: 0px;
}
.AnalysisTable {
  width: 80%;
}
.AnalysisTable tr {
  line-height: 30px;
}
.AnalysisTable tr td {
  border: 1px solid gray;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.FontWeight {
  font-weight: bold;
}
</style>