var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"100%"}},[_c('el-form',{staticClass:"demo-form-inline",attrs:{"inline":true}},_vm._l((_vm.baseInfo.noteTableData),function(item,index){return _c('div',{key:index},[_c('el-form-item',{attrs:{"label":"修订日期"}},[_c('el-input',{staticStyle:{"width":"140px"},attrs:{"placeholder":"修订日期"},model:{value:(item.revisionDate),callback:function ($$v) {_vm.$set(item, "revisionDate", $$v)},expression:"item.revisionDate"}})],1),_c('el-form-item',{attrs:{"label":"提出修订人"}},[_c('el-input',{staticStyle:{"width":"140px"},attrs:{"placeholder":"提出修订人"},model:{value:(item.requestedBy),callback:function ($$v) {_vm.$set(item, "requestedBy", $$v)},expression:"item.requestedBy"}})],1),_c('el-form-item',{attrs:{"label":"修订项目"}},[_c('el-input',{staticStyle:{"width":"140px"},attrs:{"placeholder":"修订项目"},model:{value:(item.itemsRevised),callback:function ($$v) {_vm.$set(item, "itemsRevised", $$v)},expression:"item.itemsRevised"}})],1),_c('el-form-item',{attrs:{"label":"修订人"}},[_c('el-input',{staticStyle:{"width":"140px"},attrs:{"placeholder":"修订人"},model:{value:(item.editedBy),callback:function ($$v) {_vm.$set(item, "editedBy", $$v)},expression:"item.editedBy"}})],1),_c('el-form-item',{attrs:{"label":"修订原因"}},[_c('el-input',{staticStyle:{"width":"140px"},attrs:{"placeholder":"修订原因"},model:{value:(item.reason),callback:function ($$v) {_vm.$set(item, "reason", $$v)},expression:"item.reason"}})],1),_c('el-form-item',{attrs:{"label":"批准人"}},[_c('el-input',{staticStyle:{"width":"140px"},attrs:{"placeholder":"批准人"},model:{value:(item.approvedBy),callback:function ($$v) {_vm.$set(item, "approvedBy", $$v)},expression:"item.approvedBy"}})],1),_c('el-form-item',{staticStyle:{"width":"120px"}},[(
            item.revisionDate &&
              item.requestedBy &&
              item.itemsRevised &&
              item.editedBy &&
              item.reason &&
              item.approvedBy
          )?_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.addNoteTableClick(index)}}},[_vm._v("Complete")]):_vm._e(),_vm._v("    "),(!item.isComplete && _vm.baseInfo.noteTableData.length > 1)?_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.removeNoteTableClick(index)}}},[_c('i',{staticClass:"el-icon-delete",staticStyle:{"color":"Red","font-size":"18px"}})]):_vm._e()],1)],1)}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }