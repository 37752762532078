<template>
  <div
    class="app-new-item"
    v-loading.fullscreen.lock="loading"
    element-loading-spinner="el-icon-loading"
    element-loading-text="Desperately loading..."
    :style="{ width: sWidth }"
  >
    <div class="new-item-head">
      Coa Import {{ titleType }}
      <span
        style="position: absolute; left: 10px; cursor: pointer"
        @click="back"
      >
        <i class="el-icon-arrow-left"></i>
      </span>
      <span
        style="
          position: absolute;
          right: 10px;
          cursor: pointer;
          font-weight: 400;
          font-size: 14px;
        "
      >
        <el-dropdown placement>
          <div>
            <i class="el-icon-user" style="margin-right: 10px"></i>
            {{ userInfo.username }}
            <i class="el-icon-arrow-down"></i>
            <!-- <span class="user-name">语言切换</span> -->
            <el-dropdown-menu class="user-dropdown" slot="dropdown">
              <el-dropdown-item @click.native="logoutHandle"
                >Logout</el-dropdown-item
              >
            </el-dropdown-menu>
          </div>
        </el-dropdown>
      </span>
    </div>
    <div style="position: absolute;text-align: left;margin: 120px 0 0 180px;color: red;z-index: 9;">
      <div style="height: 30px;line-height: 30px;">Rev：{{params.revNumber}}</div>
      <div style="height: 30px;line-height: 30px;">Date：{{formatTime(revDateTime,"yyyy-MM-dd hh:mm")}}</div>
    </div>
    <div class="new-item-content">
      <!-- base info -->
      <div class="base-info">
        <div style="margin-left: 6%">Base Info</div>
        <div>
          <div>
            <span>产品类别：</span>
            <el-select
              ref="productType"
              :class="{ 'velidate-item': false }"
              @change="productTypeChange"
              v-model="params.productType"
              placeholder="请选择产品类别"
              clearable
              :disabled="isSelectProType"
            >
              <el-option
                v-for="(item, i) in productTypeList"
                :key="i"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
          <div>
            <span>产品编号：</span>
            <el-autocomplete
              clearable
              ref="itemCode"
              v-model="params.BaseInfoItemCode"
              :fetch-suggestions="codeSearch"
              placeholder="请输入产品编号"
              popper-class="select-option"
              :popper-append-to-body="false"
              @select="itemCodeSearch"
            ></el-autocomplete>
          </div>
          <div>
            <span>产品名称：</span>
            <el-input
              v-model="params.BaseInfoProductName"
              placeholder="请输入产品名称"
            ></el-input>
          </div>
          <div>
            <span>版本号：</span>
            <el-input
              v-model="params.versionNumber"
              placeholder="请输入版本号"
            ></el-input>
          </div>
          <div>
            <span>批号：</span>
            <el-input
              v-model="params.BaseInfoLot"
              placeholder="请输入批号"
            ></el-input>
          </div>
          <div>
            <span>内销订单号/样品编号：</span>
            <el-input
              v-model="params.OrderNumber"
              placeholder="内销订单号/样品编号"
            ></el-input>
          </div>
          <div style="height: auto;margin-top:10px;">
            <div style="display: inline-block; text-align: left">
              <table style="border: 1px solid #eee;">
                <tr v-for="(item, baseInfoInputIndex) in BaseInfoCustomFieldAttr" :key="baseInfoInputIndex + 1">
                  <td style="border: 1px solid #eee;">
                    <el-input placeholder="请输入内容" style="width: 100%" v-model="item.baseInfoInput1"></el-input>
                  </td>
                  <td style="border: 1px solid #eee;">
                    <el-input placeholder="请输入内容" v-model="item.baseInfoInput2"></el-input>
                  </td>
                  <td>
                    <el-button type="primary" class="button-new-tag" size="small" @click="addBaseInfoInput(1, 0)">+</el-button>
                    <el-button v-show="BaseInfoCustomFieldAttr.length>1" type="danger" class="button-new-tag" size="small" @click="addBaseInfoInput(0, baseInfoInputIndex)">-</el-button>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <Liquid v-if="otherType == 'TY-L'" :baseInfoItemCodeDetail="this.$data"></Liquid>
      <OilEhxto v-else-if="otherType == 'TY-EHXTO'" :baseInfoItemCodeDetail="this.$data"></OilEhxto>
      <OilShxto v-else-if="otherType == 'TY-SHXTO'" :baseInfoItemCodeDetail="this.$data"></OilShxto>
      <Powder v-else :baseInfoItemCodeDetail="this.$data"></Powder>
    </div>
  </div>
</template>

<script>
import base from "@/common/base.js";
import Liquid from "./CoaLiquid.vue";
import OilEhxto from "./CoaOilEhxto.vue";
import Powder from "./CoaPowder.vue";
import OilShxto from "./CoaOilShxto.vue";
export default {
  name: "coa-item",
  mixins: [base],
  components: { Liquid, OilEhxto, OilShxto, Powder},
  data() {
    return {
      otherType: "",
      isSelectProType: false,
      titleType: "添加",
      productTypeList: [
        { label: "TY-P", value: "TY-P" },
        { label: "TY-L", value: "TY-L" },
        { label: "TY-EHXTO", value: "TY-EHXTO" },
        { label: "TY-SHXTO", value: "TY-SHXTO" },
      ],
      revDateTime:base.methods.getLocalNowTime(),
      params: {
        productType: "",
        BaseInfoItemCode: "",
        BaseInfoProductName: "",
        BaseInfoLot: "",
        OrderNumber:"",
        versionNumber: "",
        revNumber:1,
      },
      BaseInfoItemCodeDetail: {},
      sWidth: 0,
      loading: false,
      itemCodeSearchLock: "",
      BaseInfoCustomFieldAttr: [{
        baseInfoInput1: "",
        baseInfoInput2: ""
      }],
      baseInfoField: {
        baseInfoInput1: "",
        baseInfoInput2: ""
      },
    };
  },
  computed: {},
  methods: {
    back() {
      this.$router.push({ path: "/cn-worklist" });
    },
    addBaseInfoInput(type,index){
      if(type==1){
        this.BaseInfoCustomFieldAttr.push({ ...this.baseInfoField });
      }
      else{
        this.$delete(this.BaseInfoCustomFieldAttr,index)
      }
    },
    formatTime(time,format){
      if(time){
        return base.methods.formatTime(time,format);
      }
      else{
        return base.methods.formatTime(base.methods.getLocalNowTime(),format);
      }
    },
    productTypeChange() {
      console.log(this.params.productType);
      this.params.revNumber = 1
      this.otherType = this.params.productType;
    },
    codeSearch(queryString, cb) {
      this.getDetailByItemCodeSearch(queryString);
      // var restaurants = this.$store.state.codeList;
      var codeList = JSON.parse(localStorage.getItem("itemCodeCnListKey"));
      var list = [];
      codeList.forEach((element) => {
        list.push({ value: element.itemCode });
      });
      // var restaurants = localStorage.getItem('itemCodeListKey');
      var results = queryString
        ? list.filter(this.createFilter(queryString))
        : list;
      // 调用 callback 返回建议列表的数据

      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) !=
            -1 || restaurant.value.indexOf(queryString) != -1
        );
      };
    },
    itemCodeSearch(val) {
      if (typeof val.value != "undefined") {
        console.info('val.value');
        console.info(val.value);
        this.getDetailByItemCodeSearch(val.value);
      }
    },
    getDetailByItemCodeSearch(searchValue) {
      if (this.itemCodeSearchLock == searchValue) {
        this.itemCodeSearchLock = searchValue;
        return;
      }
      if (typeof searchValue == "undefined" || searchValue.length == 0) {
        return;
      }

      this.$api.handleCnSpec
        .getCnDetailByCnItemCode(searchValue)
        .then((res) => {
          if (res.jsonValue != undefined) {
            this.params.BaseInfoProductName = res.productName;
            this.BaseInfoItemCodeDetail = res;
            console.log('getCnDetailByCnItemCode==========res')
            console.log(res)
            var jsonValue=JSON.parse(res.jsonValue)
            console.log('getCnDetailByCnItemCode==========jsonValue')
            console.log(jsonValue)
            if(jsonValue.baseInfoCustomField){
              this.BaseInfoCustomFieldAttr = JSON.parse(jsonValue.baseInfoCustomField)
            }
            this.params.productType=jsonValue.productType
            this.params.versionNumber = jsonValue.versionNumber;
            this.params.revNumber = jsonValue.revNumber;
            this.otherType=jsonValue.productType
            //#endregion
            console.log("this.BaseInfoItemCodeDetail");
            console.log(this.BaseInfoItemCodeDetail);
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
     
  },
  mounted() {
    this.titleType = "添加";
    let params = { ...this.$route.query };
    //console.log(this.otherType);
    if (params.id) {
      this.titleType = "修改";
      this.otherType=params.productType;
      this.isSelectProType = true;
      //this.getData(params.id);
      this.newItemType = "Edit";
    } else {
      this.newItemType = "New";
    }
    this.sWidth = "1920px";
  },
  created() {},
 
};
</script>
<style lang="scss" scoped>
@import "../module/NewItemStyle.scss";
</style>

