<template>
  <div style="width: 100%;">
    <el-form :inline="true" class="demo-form-inline">
      <div v-for="(item, index) in baseInfo.noteTableData" :key="index">
        <el-form-item label="修订日期">
          <el-input
            v-model="item.revisionDate"
            placeholder="修订日期"
            style="width: 140px"
          ></el-input>
        </el-form-item>
        <el-form-item label="提出修订人">
          <el-input
            v-model="item.requestedBy"
            placeholder="提出修订人"
            style="width: 140px"
          ></el-input>
        </el-form-item>
        <el-form-item label="修订项目">
          <el-input
            v-model="item.itemsRevised"
            placeholder="修订项目"
            style="width: 140px"
          ></el-input>
        </el-form-item>
        <el-form-item label="修订人">
          <el-input
            v-model="item.editedBy"
            placeholder="修订人"
            style="width: 140px"
          ></el-input>
        </el-form-item>
        <el-form-item label="修订原因">
          <el-input
            v-model="item.reason"
            placeholder="修订原因"
            style="width: 140px"
          ></el-input>
        </el-form-item>
        <el-form-item label="批准人">
          <el-input
            v-model="item.approvedBy"
            placeholder="批准人"
            style="width: 140px"
          ></el-input>
        </el-form-item>
        <el-form-item style="width: 120px">
          <el-button
            type="text"
            @click="addNoteTableClick(index)"
            v-if="
              item.revisionDate &&
                item.requestedBy &&
                item.itemsRevised &&
                item.editedBy &&
                item.reason &&
                item.approvedBy
            "
            >Complete</el-button
          >
          &nbsp;&nbsp;
          <el-button
            type="text"
            v-if="!item.isComplete && baseInfo.noteTableData.length > 1"
            @click="removeNoteTableClick(index)"
          >
            <i style="color:Red;font-size:18px;" class="el-icon-delete"></i>
          </el-button>
        </el-form-item>
      </div>
    </el-form>
  </div>
</template>
<script>
export default {
  props: { baseInfo: {} },
  data() {
    return {
      noteTableData: [
        {
          revisionDate: "",
          requestedBy: "",
          itemsRevised: "",
          editedBy: "",
          reason: "",
          approvedBy: "",
          isComplete: false
        }
      ]
    };
  },
  methods: {
    addNoteTableClick(nowIndex) {
      this.baseInfo.noteTableData.forEach((item, index) => {
        if (index == nowIndex) {
          item.isComplete = true;
        }
      });
      console.log('this.baseInfo.addRevNumber==='+this.baseInfo.addRevNumber)
      if(this.baseInfo.addRevNumber){
        let revNumber = 0
        this.baseInfo.noteTableData.forEach((item)=>{
          if(item.isComplete == true){
            revNumber++
          }
        })
        if(this.baseInfo.pageType){
          this.baseInfo.formModel.revNumber=revNumber+1
        }
        else{
          this.baseInfo.params.revNumber=revNumber+1
          this.$parent.fatherMethod(revNumber);
        }
      }
      this.baseInfo.noteTableData.push({
        revisionDate: "",
        requestedBy: "",
        itemsRevised: "",
        editedBy: "",
        approvedBy: "",
        reason: "",
        isComplete: false
      });
    },
    removeNoteTableClick(idx) {
      this.baseInfo.noteTableData.splice(idx, 1);
    }
  },
  created() {},
  mounted() {}
};
</script>
